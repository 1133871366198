import { NavLink } from "react-router-dom"

const UserEndMembership = () => {
    return (
        <div className={'fe-wrapper' + (/iPad|iPhone|iPod/.test(navigator.userAgent) ? ' ios-full' : '')}>
            <div className='box'>
                <span className='logo'></span>
                <div className='form'>
                    <form className="form-add">
                        <h2>Mitgliedschaft beendet</h2>
                        <p>Wir bedauern, dass Sie uns verlassen haben und wünschen Ihnen für die Zukunft alles Gute!</p>
                    </form>
                </div>
                <div className='options'>
                    <div className='smalltext last'>
                        <NavLink to="https://www.feworia.de">Zur Startseite</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserEndMembership
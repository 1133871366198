import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from '../store/global/actions';
import HelperService from '../services/helper.service';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ConstructionIcon from '@mui/icons-material/Construction';
import LoginIcon from '@mui/icons-material/Login';
import { Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import useOnClickOutside from '../hooks/useOnClickOutside';

const Topbar = () => {
    const dispatch = useDispatch();
    const navstate = useSelector(state => state.global.navstate);
    let openNavIcon = navstate !== 'opened' ? <MenuIcon className='icon' onClick={() => { dispatch(GlobalStore.setNavState('opened')) }} /> : <MenuOpenIcon className='icon' onClick={() => { dispatch(GlobalStore.setNavState('closed')) }} />;

    const userNavRef = useRef(null);
    const [userNavState, setUserNavState] = useState('closed');
    useOnClickOutside(userNavRef, () => {
        if (userNavState) {
            setUserNavState('closed');
        }
    });

    let navigate = useNavigate();
    const logout = () => {
        dispatch(GlobalStore.setNavState('opened'))
        dispatch(GlobalStore.setUser(false));
        navigate("/");
    }

    const user = useSelector(state => state.global.user);
    const userdata = user.data;

    const adminuser = useSelector(state => state.global.adminuser);
    const changeToAdmin = () => {
        if (adminuser) {
            dispatch(GlobalStore.setUser(adminuser))
            navigate("/");
        }
    }

    return (
        <div className='topbar'>
            <div className="wrapper">
                {openNavIcon}
                <div className='items'>
                    <div className="item user" onClick={() => setUserNavState(userNavState === 'closed' ? 'open' : 'closed')}>
                        <img src={userdata.picture ? userdata.picture : HelperService.getDefaultUserPicture()} alt={userdata.firstname + ' ' + userdata.lastname} />
                        <span className='name'>{userdata.firstname} {userdata.lastname}</span>
                        <div className={"usernav " + userNavState} ref={userNavRef}>
                            <button type='button' onClick={() => { navigate("/mein-konto") }}><ConstructionIcon className='icon' /> Mein Konto</button>
                            <button type='button' onClick={() => { logout() }}><PowerSettingsNewIcon className='icon' style={{ color: '#9E0101' }} /> Abmelden</button>
                        </div>
                    </div>
                    {HelperService.checkAdmin(user) && <div className="item"><Tooltip title="Zum Adminbereich" placement="bottom-end" arrow><LoginIcon className='icon' onClick={() => { changeToAdmin() }}></LoginIcon></Tooltip></div>}
                </div>
            </div>
        </div>
    )
}

export default Topbar